import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from '@/components/link';
import parse from 'html-react-parser';

export const LevelThree = ({
    links,
    levelTwo,
    setLevelTwo,
    setLevelOne,
    setActive,
    mobile_menu_back_label,
}) => {
    const [exitingItems, setExitingItems] = useState(new Set());

    useEffect(() => {
        if (levelTwo) {
            const timer = setTimeout(() => {
                setExitingItems((prev) => {
                    const newSet = new Set(prev);
                    links.forEach((item) => {
                        item.links?.forEach((subItem) => {
                            if (subItem.id !== levelTwo) {
                                newSet.delete(subItem.id);
                            }
                        });
                    });
                    return newSet;
                });
            }, 100); // Match animation duration
            return () => clearTimeout(timer);
        }
    }, [levelTwo, links]);

    return (
        <ul className="mobile__list">
            {links?.map((item) => {
                const { links: subLinks } = item;
                return subLinks?.map((subItem) => {
                    const { links, id, text, description } = subItem;
                    const isVisible = levelTwo === id;
                    const isExiting = exitingItems.has(id);

                    if (!isVisible && !isExiting) return null;

                    return (
                        <li
                            key={id}
                            className={classNames('mobile__item', {
                                'mobile__item--animated':
                                    isVisible && !isExiting,
                                'mobile__item--exit': isExiting,
                            })}
                            onAnimationEnd={() => {
                                if (isExiting) {
                                    setExitingItems((prev) => {
                                        const newSet = new Set(prev);
                                        newSet.delete(id);
                                        return newSet;
                                    });
                                }
                            }}
                        >
                            <button
                                className="mobile__back"
                                onClick={() => {
                                    setExitingItems((prev) =>
                                        new Set(prev).add(id)
                                    );
                                    setTimeout(() => setLevelTwo(null), 100);
                                }}
                            >
                                {mobile_menu_back_label}
                            </button>
                            <h3 className="mobile__link--lg mb-2">{text}</h3>
                            <div className="mobile__description">
                                {description}
                            </div>
                            <hr className="mobile__seperator" />
                            <ul className="mobile__sublist">
                                {links?.map((item, index) => {
                                    const { text, url } = item;
                                    return (
                                        <li
                                            className="mobile__item"
                                            key={index}
                                        >
                                            <Link
                                                href={url}
                                                className={classNames(
                                                    'mobile__link mobile__link--link'
                                                )}
                                                onClick={() => {
                                                    setActive(false);
                                                    setLevelOne(null);
                                                    setLevelTwo(null);
                                                }}
                                            >
                                                {parse(text)}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                });
            })}
        </ul>
    );
};
