import dynamic from 'next/dynamic';
import { useParams } from 'next/navigation';
import { useState, useRef, useEffect } from 'react';

const ReactSelectDropdownIndicator = dynamic(() =>
    import('react-select').then((module) => module.components.DropdownIndicator)
);
const AsyncSelect = dynamic(() => import('react-select/async'));

import { Icon } from '@/components/icon';
import { useSearch as useSearchApi } from '@/lib/hooks/use-search';
import { useRouter } from 'next/navigation';
import { getAlgoliaSearchCalls } from '@/lib/utils/get-search';
import classNames from 'classnames';
import { useClickOutside } from '@/lib/hooks/use-click-outside';

const getContainerClass = (isMobileView) => {
    return classNames(
        { 'async-select-container': !isMobileView },
        { 'async-select-mobile-container': isMobileView }
    );
};

const getControlClass = (isMobileView) => {
    return classNames(
        { 'async-select': !isMobileView },
        { 'async-select-mobile': isMobileView }
    );
};

const DropdownIndicator = (props) => {
    const { isMobileView, onHandleSearchClick, onSetIsOpen, controlClass } =
        props.selectProps;
    return (
        <ReactSelectDropdownIndicator {...props}>
            {!isMobileView && (
                <a
                    onClick={onSetIsOpen}
                    tabIndex="0"
                    onKeyDownCapture={onSetIsOpen}
                >
                    <Icon
                        className={`${controlClass}__xmark`}
                        icon={'fa-light fa-xmark'}
                    />
                </a>
            )}
            <a
                onMouseDown={onHandleSearchClick}
                onTouchStart={onHandleSearchClick}
                tabIndex="0"
                onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                        onHandleSearchClick;
                    }
                }}
            >
                <Icon
                    className={`${controlClass}__magnifying_glass`}
                    icon={'fa-regular fa-magnifying-glass'}
                />
            </a>
        </ReactSelectDropdownIndicator>
    );
};

export const SearchAutoComplete = ({
    search_placeholder,
    isMobileView,
    handleToggle,
}) => {
   const router = useRouter();
    const { locale } = useParams();
    const language= locale ? locale : 'default';
    const [query, setQuery] = useState('');
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const searchApi = useSearchApi(getAlgoliaSearchCalls(language));
    const ref = useRef();
    const { isOpen, setIsOpen } = useClickOutside(ref);
    
    useEffect(() => {
        setIsOpen(isMobileView);
    }, [isMobileView]);

    useEffect(() => {
        if (!isOpen) {
            setMenuIsOpen(false);
            setQuery('');
        }
    }, [isOpen]);

    const fetchData = (input, callBack) => {
        if (input && input.length > 2) {
            searchApi.request(input).then((data) => {
                if (data && data.length > 0) {
                    //This will remove the dummy press release page from search results
                    data = data.filter(
                        (item) => item.url_slug !== 'http://press-release-1'
                    );
                    setMenuIsOpen(true);
                    data.unshift({ url_slug: 'searchInput', value: null });
                } else {
                    setMenuIsOpen(false);
                }
                callBack(data);
            });
        } else {
            callBack([]);
            setMenuIsOpen(false);
        }
    };
    const handleOpen = (e) => {
        if (e.type === 'keydown') {
            if (e.key === 'Enter') setIsOpen(!isOpen);
        } else setIsOpen(!isOpen);
    };
    const handleSelectedOption = (option, { action }) => {
        if (action === 'select-option') {
            if (option) {
                setIsOpen(isMobileView || false);
                if (isMobileView) {
                    handleToggle();
                }

                let navigationUrl = '/${language}';
                if (option.url_slug === 'searchInput') {
                    router.push(`/${language}/search?q=${query}`);
                } else if (option.url_slug !== navigationUrl) {
                    if (option.url_slug.includes('https'))
                        navigationUrl = `${option.url_slug}`;
                    else navigationUrl = `/${language}/${option.url_slug}`;
                    router.replace(`${navigationUrl}`);
                } else router.replace(`${navigationUrl}`);
            }
        }
    };
    const handleInputChange = (value, { action }) => {
        if (action !== 'input-blur' && action !== 'menu-close') {
            setQuery(value);
        }
        if (action === 'menu-close') {
            setMenuIsOpen(false);
        }
    };
    const handleSearchClick = () => {
        if (query && query !== '') {
            setIsOpen(isMobileView || false);
            if (isMobileView) {
                handleToggle();
            }
            router.push(`/${language}/search?q=${query}`);
        }
    };

    const containerClass = getContainerClass(isMobileView);
    const controlClass = getControlClass(isMobileView);

    return (
        <>
            {!isMobileView && (
                <span
                    className={classNames({
                        'navbar__search-icon': !isOpen,
                        'navbar__search-icon--hidden': isOpen,
                    })}
                    onClick={handleOpen}
                    tabIndex="0"
                    onKeyDownCapture={handleOpen}
                >
                    <Icon icon={'fa-regular fa-magnifying-glass'} />
                </span>
            )}
            {isOpen && (
                <span ref={isMobileView ? null : ref}>
                    <AsyncSelect
                        placeholder={search_placeholder}
                        className={containerClass}
                        classNamePrefix={controlClass}
                        loadOptions={fetchData}
                        onChange={handleSelectedOption}
                        onHandleSearchClick={handleSearchClick}
                        onSetIsOpen={(e) => {
                            if (e.type === 'keydown') {
                                if (e.key === 'Enter') setIsOpen(false);
                                setMenuIsOpen(false);
                            } else setIsOpen(false);
                            setMenuIsOpen(false);
                        }}
                        components={{ DropdownIndicator }}
                        onInputChange={handleInputChange}
                        cacheOptions={false}
                        escapeClearsValue={true}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        autoFocus={!isMobileView}
                        openMenuOnClick={false}
                        menuIsOpen={menuIsOpen}
                        isMobileView={isMobileView}
                        controlClass={controlClass}
                        tabSelectsValue={false}
                        onKeyDown={(e) => {
                            if (e.key == 'Enter') {
                                if (!menuIsOpen) {
                                    handleSearchClick();
                                }
                            }
                        }}
                        inputValue={query}
                    />
                </span>
            )}
        </>
    );
};
