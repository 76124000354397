
import { algoliasearch } from 'algoliasearch';
import { custom_release_locales } from '@/assets/data/data';
const News_Release_Countries = [
    'ca-en',
    'mx',
    'gb',
    'ch-en',
    'cr',
    'pr',
    'sg',
    'ca-fr',
    'kr',
    'jp',
];

const Orchard_Countries = ['de', 'it', 'es'];

const client = algoliasearch(
    process.env.ALGOLIA_APPLICATION_ID,
    process.env.ALGOLIA_SEARCH_API_KEY
);

export const getSearchResults = (hits) => {
    let results = [];
    if (hits && hits.length > 0) {
        hits.forEach((hit) => {
            if (hit.title) {
                hit.url_slug = !hit.url_slug ? '/' : hit.url_slug;
                results.push({
                    ...hit,
                    label: hit.title,
                    value: hit.objectID,
                });
            }
        });
    }
    return results;
};

export const getAlgoliaSearchCalls = (language) => {
    if (language === 'default')
        return [
            (query) => client.searchSingleIndex({ indexName: `${language}-language-pages`,searchParams: { query } }),
            (query) => client.searchSingleIndex({ indexName: 'press-releases', searchParams: { query }}),
        ];
    else if (News_Release_Countries.includes(language))
        return [
            (query) => client.searchSingleIndex({ indexName: `${language}-language-pages` ,searchParams: { query } }),
            (query) => client.searchSingleIndex({
                indexName: custom_release_locales.includes(language) ? `${language}-press-releases` : 'press-releases',
                searchParams: { query }
            }),
        ];
    else if (Orchard_Countries.includes(language))
        return [
            (query) => client.searchSingleIndex({ indexName: `${language}-language-pages` ,searchParams: { query }}),
            (query) => client.searchSingleIndex({ indexName: `${language}-pages-1.0` ,searchParams: { query }}),
            (query) => client.searchSingleIndex({ indexName: 'press-releases' ,searchParams: { query } }),
        ];
    else return [(query) => client.searchSingleIndex({ indexName: `${language}-language-pages`,searchParams: { query } })];
};
