import { Link } from '@/components/link';

export const FooterLink = ({ title, url }) => {
    return (
        <li className="footer__item">
            <Link href={url} className="footer__link">
                {title}
            </Link>
        </li>
    );
};
