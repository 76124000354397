import classNames from 'classnames';

export const Trigger = ({ active, handleToggle }) => {
    return (
        <button
            className={classNames('mobile__trigger', {
                'mobile__trigger--active': active,
            })}
            onClick={() => handleToggle()}
            aria-expanded={active}
            aria-label={active ? 'Close menu' : 'Open Menu'}
        >
            <div className="mobile__trigger-line mobile__trigger-line--1"></div>
            <div className="mobile__trigger-line mobile__trigger-line--2"></div>
            <div className="mobile__trigger-line mobile__trigger-line--3"></div>
        </button>
    );
};
