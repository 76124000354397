'use client';

import { SessionProvider } from 'next-auth/react';
import { getPreviewMode } from '@/lib/utils/get-preview-mode';
import TagManager from 'react-gtm-module';
import KontentSmartLink from '@kontent-ai/smart-link';
import { useEffect } from 'react';
import { getFontFamily } from '@/lib/utils/get-font-family';
import { Notification } from '@/components/notification';
import { Navbar } from '@/components/navbar';
import { Footer } from '@/blocks/footer';
import { StandaloneNavbar } from '@/components/standalone-navbar';
import { StandaloneFooter } from '@/components/standalone-footer';
import { UtilityNavigation } from '@/components/utility-navigation';

export const SiteLayout = ({ data, children }) => {
    useEffect(() => {
        if (!getPreviewMode()) return;
        const sdk = KontentSmartLink.initialize({
            defaultDataAttributes: {
                projectId: process.env.KONTENT_PROJECT_ID,
                languageCodename: data.locale,
            },
            queryParam: 'edit',
        });
        return () => {
            sdk.destroy();
        };
    }, []);

    useEffect(() => {
        if (!data?.head?.gtm || process.env.NODE_ENV === 'development') return;
        TagManager.initialize({ gtmId: `GTM-${data.head.gtm}` });
    }, []);

    return (
        <SessionProvider>
            <div
                className={`${data?.head?.font} ${getFontFamily({
                    locale: data.locale,
                })}`}
                style={{ ...data?.theme }}
            >
                <div id="portal" />
                {data?.notification && <Notification {...data?.notification} />}
                <div
                    className="wrapper"
                    data-kontent-project-id={process.env.KONTENT_PROJECT_ID}
                    data-kontent-language-codename={
                        data.head?.language_codename
                    }
                >
                    {!data.standalone_page_settings && (
                        <>
                            {data.utility_navigation && (
                                <UtilityNavigation
                                    {...data.utility_navigation}
                                />
                            )}
                            {data.navbar && <Navbar {...data.navbar} />}

                            <main id="main">{children}</main>

                            {data.footer && <Footer {...data.footer} />}
                        </>
                    )}
                    {data.standalone_page_settings && (
                        <>
                            {data.navbar && (
                                <StandaloneNavbar
                                    {...data.standalone_page_settings}
                                />
                            )}
                            <main id="main">{children}</main>
                            {data.footer && (
                                <StandaloneFooter
                                    {...data.standalone_page_settings}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </SessionProvider>
    );
};
