'use client';

import { Link } from '@/components/link';
import { useRef, useState } from 'react';
import { Icon } from '@/components/icon';
import { useClickOutside } from '@/lib/hooks/use-click-outside';
import classNames from 'classnames';

export const CountrySelect = ({ countries, locale }) => {
    const country = countries.find(
        (country) => country.locale.replace('_', '-') === locale
    );
    const [activeCountry, setActiveCountry] = useState(country);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const ref = useRef();
    const { isOpen, setIsOpen } = useClickOutside(ref);

    return (
        <div className="country" ref={ref}>
            <button onClick={handleClick} className="country__trigger">
                <Icon className="country__globe" icon="fa-light fa-globe" />
                <div className="country__title">
                    {activeCountry && activeCountry.title}
                </div>
                <div className="country__arrow">
                    <Icon
                        wrapperIconClassName={classNames('country__icon', {
                            'country__icon--active': isOpen,
                        })}
                        icon={'fa-solid fa-chevron-down'}
                    />
                </div>
            </button>
            {isOpen && (
                <div className="country__dropdown">
                    {countries.map((country) => {
                        return (
                            <Link
                                key={country.locale}
                                href={country.url}
                                className="country__dropdown-item"
                            >
                                {country.title}
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
