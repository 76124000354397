import {
    merriweather_sans,
    noto_sans_jp,
    noto_sans_kr,
    noto_sans_looped_th,
} from '@/lib/utils/fonts';

export const getFontFamily = ({ locale }) => {
    const site = process.env.KONTENT_COLLECTION;
    const sites = {
        default: {
            jp: noto_sans_jp.className,
            kr: noto_sans_kr.className,
        },
        benmakusho: {
            default: noto_sans_jp.className,
        },
        tavi_web: {
            default: noto_sans_jp.className,
        },
        newheartvalve_sea: {
            th: noto_sans_looped_th.className,
        },
    };

    return sites?.[site]?.[locale] || merriweather_sans.className;
};
