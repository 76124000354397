import { Link } from '@/components/link';
import Image from 'next/image';

export const Brand = ({ logo, utility_navigation }) => {
    return (
        <>
            {logo && (
                <Link href={logo.url} className="mobile__brand">
                    <Image
                        src={logo.src.mobile.src || logo.src.desktop.src}
                        width={logo.src.mobile.width || logo.src.desktop.width}
                        height={
                            logo.src.mobile.height || logo.src.desktop.height
                        }
                        alt={logo.src.alt}
                        priority
                    />
                </Link>
            )}
            {/* If no main navigation is present attempt to fallback to utility navigation logo */}
            {!logo && utility_navigation?.logo && (
                <Link href="/" className="mobile__brand">
                    <Image
                        src={
                            utility_navigation.logo.mobile.src ||
                            utility_navigation.logo.desktop.src
                        }
                        width={
                            utility_navigation.logo.mobile.width ||
                            utility_navigation.logo.desktop.width
                        }
                        height={
                            utility_navigation.logo.mobile.height ||
                            utility_navigation.logo.desktop.height
                        }
                        alt={utility_navigation.logo.alt}
                        priority
                    />
                </Link>
            )}
        </>
    );
};
