import { LevelOne } from './level-one';
import { LevelThree } from './level-three';
import { LevelTwo } from './level-two';
import { Search } from './search';

export const NavItems = ({
    links,
    setLevelOne,
    setLevelTwo,
    levelOne,
    levelTwo,
    setActive,
    utility_navigation,
    remove_search_functionality,
    handleToggle,
    search_placeholder,
    active,
    mobile_menu_back_label,
}) => {
    return (
        <nav className="mobile__nav">
            {/* Search */}
            {!remove_search_functionality && active && (
                <Search
                    remove_search_functionality={remove_search_functionality}
                    search_placeholder={search_placeholder}
                    handleToggle={handleToggle}
                />
            )}

            {/* Level One */}

            {!levelOne && (
                <LevelOne
                    links={links}
                    setLevelOne={setLevelOne}
                    setActive={setActive}
                    utility_navigation={utility_navigation}
                />
            )}

            {/* Level Two */}

            {levelOne && !levelTwo && (
                <LevelTwo
                    links={links}
                    levelOne={levelOne}
                    setLevelOne={setLevelOne}
                    setLevelTwo={setLevelTwo}
                    setActive={setActive}
                    mobile_menu_back_label={mobile_menu_back_label}
                />
            )}

            {/* Level 3 */}

            {levelTwo && (
                <LevelThree
                    links={links}
                    levelTwo={levelTwo}
                    setLevelOne={setLevelOne}
                    setLevelTwo={setLevelTwo}
                    setActive={setActive}
                    mobile_menu_back_label={mobile_menu_back_label}
                />
            )}
        </nav>
    );
};
