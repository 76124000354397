import { Icon } from '@/components/icon';

export const SocialLinks = ({ social_links }) => {
    return (
        <>
            <div className="social__links">
                {social_links?.map((link, index) => {
                    const { text, icon, url } = link;
                    return (
                        <a
                            href={url}
                            aria-label={text}
                            className="social__link"
                            key={index}
                        >
                            <Icon
                                icon={icon}
                                className="social__item"
                                alt={text}
                            />
                        </a>
                    );
                })}
            </div>
        </>
    );
};
