import { useEffect, useState } from 'react';
import { Icon } from '@/components/icon';
import classNames from 'classnames';
import { Link } from '@/components/link';
import parse from 'html-react-parser';

export const LevelTwo = ({
    links,
    levelOne,
    setLevelOne,
    setLevelTwo,
    setActive,
    mobile_menu_back_label,
}) => {
    const [exitingItems, setExitingItems] = useState(new Set());

    useEffect(() => {
        if (levelOne) {
            const timer = setTimeout(() => {
                setExitingItems((prev) => {
                    const newSet = new Set(prev);
                    links.forEach((item) => {
                        if (item.id !== levelOne) {
                            newSet.delete(item.id);
                        }
                    });
                    return newSet;
                });
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [levelOne, links]);

    return (
        <ul className="mobile__list">
            {links?.map((item) => {
                const { links, text, id } = item;
                const isVisible = levelOne === id;
                const isExiting = exitingItems.has(id);

                if (!isVisible && !isExiting) return null;

                return (
                    <li
                        key={id}
                        className={classNames('mobile__item', {
                            'mobile__item--animated': isVisible && !isExiting,
                            'mobile__item--exit': isExiting,
                        })}
                        onAnimationEnd={() => {
                            if (isExiting) {
                                setExitingItems((prev) => {
                                    const newSet = new Set(prev);
                                    newSet.delete(id);
                                    return newSet;
                                });
                            }
                        }}
                    >
                        <button
                            className="mobile__back"
                            onClick={() => {
                                setExitingItems((prev) =>
                                    new Set(prev).add(id)
                                );
                                setTimeout(() => setLevelOne(null), 100);
                            }}
                        >
                            {mobile_menu_back_label}
                        </button>
                        <h3 className="mobile__link--lg">{text}</h3>
                        <hr className="mobile__seperator" />
                        <ul className="mobile__sublist">
                            {links?.map((item, index) => {
                                const { links, id, url } = item;
                                const text = item.text.replace('<br>', ' ');
                                return (
                                    <li key={index} className="mobile__item">
                                        {links && (
                                            <button
                                                className="mobile__link mobile__dropdown mobile__subdropdown"
                                                onClick={() => {
                                                    setLevelTwo(id);
                                                }}
                                            >
                                                <span>{parse(text)}</span>
                                                <Icon icon="fa-solid fa-chevron-right" />
                                            </button>
                                        )}
                                        {!links && (
                                            <Link
                                                href={url}
                                                className="mobile__link mobile__link--link"
                                                onClick={() => {
                                                    setActive(false);
                                                    setLevelOne(null);
                                                    setLevelTwo(null);
                                                }}
                                            >
                                                {parse(text)}
                                            </Link>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </ul>
    );
};
