'use client';

import { Icon } from '@/components/icon';
import { RichText } from '@/components/rich-text';

export const Notification = ({ description }) => {
    const [notificationOpen, setNotificationOpen] = useState(true);
    function closeNotification() {
        setNotificationOpen(false);
    }

    return (
        notificationOpen && (
            <div className="notification">
                <div className="notification__container">
                    {description && (
                        <RichText
                            className="notification__content"
                            data={description}
                        />
                    )}
                    <button
                        className="notification__close"
                        onClick={closeNotification}
                    >
                        <Icon icon="fa-solid fa-close" />
                    </button>
                </div>
            </div>
        )
    );
};
