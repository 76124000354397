import React from 'react';
import { Button } from '@/components/button';
import Image from 'next/image';
import { Link } from '@/components/link';

export const UtilityNavigation = ({ links, logo }) => {
    return (
        <nav className={'utilnav'}>
            <div className="container utilnav__container">
                <Link href="/" className="utilnav__logo">
                    <Image
                        src={logo.desktop.src}
                        width={logo.desktop.width}
                        height={logo.desktop.height}
                        alt={logo.alt}
                        priority
                    />
                </Link>
                <div className="utilnav__items">
                    <div className="utilnav__links">
                        {links.map((link) => {
                            return (
                                <Button
                                    className={'utilnav__link'}
                                    {...link}
                                    key={link.id}
                                    btn_size="sm"
                                >
                                    {link.cta_text}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </nav>
    );
};
