import { SkipContent } from '@/components/skip-content';
import { Desktop } from './variants/desktop';
import { Mobile } from './variants/mobile';
import classNames from 'classnames';

export const Navbar = ({ ...props }) => {
    return (
        <header
            className={classNames('header', {
                'header--sticky': props.sticky,
            })}
        >
            <SkipContent {...props} />
            <Desktop {...props} />
            <Mobile {...props} />
        </header>
    );
};
