import { Footer1a } from '@/blocks/footer/variants/footer-1a';

const prefix = 'footer';

const variants = {
    [`${prefix}_1a`]: Footer1a,
};

export const Footer = ({ ...props }) => {
    const Component = variants[props.variant];
    return Component ? <Component {...props} /> : null;
};
