import { SearchAutoComplete } from '@/features/search/ui/components/search-auto-complete';

export const Search = ({
    remove_search_functionality,
    search_placeholder,
    handleToggle,
}) => {
    return (
        <div className="mobile__search">
            {!remove_search_functionality && search_placeholder && (
                <SearchAutoComplete
                    search_placeholder={search_placeholder}
                    isMobileView={true}
                    handleToggle={handleToggle}
                />
            )}
        </div>
    );
};
